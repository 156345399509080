import React, { useState, useRef, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { StaticImage } from "gatsby-plugin-image"
import { Navigation } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

export const ContentsSwiper = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef()
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth < 768);

  const onSlideChange = swiper => {
    setActiveIndex(swiper.activeIndex)
  }

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div className="swiper-wrapper mb-8 relative">
      <Swiper
        modules={[Navigation]}
        onSlideChange={onSlideChange}
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        slidesPerView={1}
        breakpoints={{ 768: { slidesPerView: 5, spaceBetween: 70 } }}
      >
        <SwiperSlide>
          <div className="mb-3 text-center">
            <StaticImage
              src="../../images/product/sns-account/type_01.png"
              alt="クーポン発行"
              layout="constrained"
              height={432}
              width={200}
            />
          </div>
          <h3 className="font-bold mb-2 text-center text-lg">クーポン発行</h3>
          <p>
            実店舗への集客を支援！「消込型」「時限式」「期間中無制限」3種類のクーポンを配布。
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-3 text-center">
            <StaticImage
              src="../../images/product/sns-account/type_02.png"
              alt="インスタントウィン"
              layout="constrained"
              height={432}
              width={200}
            />
          </div>
          <h3 className="font-bold mb-2 text-center text-lg">
            インスタントウィン
          </h3>
          <p>
            当選者へDMでデジタルギフト券を送付！「その場で当たる」人気のXフォローRTキャンペーン！
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-3 text-center">
            <StaticImage
              src="../../images/product/sns-account/type_03.png"
              alt="リポストorいいねキャンペーン"
              layout="constrained"
              height={432}
              width={200}
            />
          </div>
          <h3 className="font-bold mb-2 text-center text-lg">
            リポストorいいね
            <br />
            キャンペーン
          </h3>
          <p>投票形式のキャンペーンでファンのエンゲージメントをUP！</p>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-3 text-center">
            <StaticImage
              src="../../images/product/sns-account/type_04.png"
              alt="X運用"
              layout="constrained"
              height={432}
              width={200}
            />
          </div>
          <h3 className="font-bold mb-2 text-center text-lg">X運用</h3>
          <p>
            予約投稿・RTキャンペーン自動化・DM送付代行まで！成果の出るX集客へ。
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-3 text-center">
            <StaticImage
              src="../../images/product/sns-account/type_05.png"
              alt="ハッシュタグキャンペーン"
              layout="constrained"
              height={432}
              width={200}
            />
          </div>
          <h3 className="font-bold mb-2 text-center text-lg">
            ハッシュタグキャンペーン
          </h3>
          <p>
            ユーザー投稿を増やして購買促進へ！カンバボタンを使った企画もOK。
          </p>
        </SwiperSlide>
      </Swiper>
      {isMobile && (
        <>
          <button
            onClick={() => swiperRef.current?.slidePrev()}
            disabled={activeIndex === 0}
            className="absolute z-10 prev-button text-white w-10 h-10 flex items-center justify-center disabled:hidden"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={() => swiperRef.current?.slideNext()}
            disabled={activeIndex === 2}
            className="absolute z-10 next-button text-white w-10 h-10 flex items-center justify-center disabled:hidden"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </>
      )}
    </div>
  )
}
