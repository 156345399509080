import React, { useState, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

import "swiper/css"
import "swiper/css/navigation"

const options = [
  {
    title: "当選者\n自動送信",
    img: "option_01",
  },
  {
    title: "WEBサイト\n誘導",
    img: "option_02",
  },
  {
    title: "LINE誘導",
    img: "option_03",
  },
  {
    title: "資料配布",
    img: "option_04",
  },
  {
    title: "プレゼントごと\nの当選確率設定",
    img: "option_05",
  },
  {
    title: "当選基準\n設定",
    img: "option_06",
  },
  {
    title: "当選落選\n画像カスタム",
    img: "option_07",
  },
  {
    title: "データ収集・落選\nCSV出力",
    img: "option_08",
  },
]

export const OptionsSwiper = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 80, height: 80)
          }
        }
      }
    }
  `)

  const images = data.allFile.nodes.reduce((acc, node) => {
    acc[node.relativePath] = node
    return acc
  }, {})

  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef()
  const onSlideChange = swiper => {
    setActiveIndex(swiper.activeIndex)
  }

  return (
    <div className="swiper-wrapper relative">
      <Swiper
        slidesPerView={2}
        spaceBetween={10}
        modules={[Navigation]}
        onSlideChange={onSlideChange}
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        breakpoints={{
          1024: { slidesPerView: 5 },
          768: { slidesPerView: 3.5 },
        }}
      >
        {options.map((option, index) => {
          const imageKey = `product/sns-account/${option.img}.png`
          const imageData = images[imageKey]?.childImageSharp
            ? getImage(images[imageKey].childImageSharp)
            : null
          return (
            <SwiperSlide key={index}>
              <div className="rounded-full bg-sns_light_green whitespace-pre-line w-40 h-40 flex flex-col items-center justify-center">
                {imageData && (
                  <GatsbyImage
                    image={imageData}
                    alt={option.title}
                    className="w-20 h-20"
                  />
                )}
                <p className="text-center text-sm font-bold">{option.title}</p>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div>
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          disabled={activeIndex === 0}
          className="absolute z-10 prev-button text-white w-10 h-10 flex items-center justify-center disabled:hidden"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()}
          disabled={activeIndex === 2}
          className="absolute z-10 next-button text-white w-10 h-10 flex items-center justify-center disabled:hidden"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  )
}
